.container {
}

::-webkit-scrollbar {
  width: 0.4rem;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #240370;
  border-radius: 10px;
}

.title {
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.5rem;
}

.list {
  border: 1px solid #240370;
  max-height: 23rem;
  overflow-y: scroll;
}

.item {
  color: inherit;
  display: flex;
}

.content {
  padding: 0 1rem;
  font-size: x-small;
}
